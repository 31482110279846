import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import crypto from 'crypto-js';
import auth from "./module/auth";
import user from "./module/user";
import places from "./module/places";
import utility from "../mixins/utility";
import createPersistedState from "vuex-persistedstate";
import router from '@/router/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		user,
		places
	},
	plugins: [
		createPersistedState({
			key: "prismApp",
			paths: ['auth', 'user', 'places']
		}),
	],
	state: {
		// 本来はこれはconfigに書く内容
		api_url: "http://takasago-amida-prism.vegetalia.jp/feed",
		// 本来は以下3つはログインフォームからの入力を使うが現状埋め込み
		// api_email: "gg-00027-api@vegetalia.co.jp",
		// api_password: "takasago",
		// api_email: "yosisako@affrc.go.jp",
		// api_password: "takicho",
		api_groupkey: "gg-00027",
		iconColors: ["#9dc4e6", "#ffc001", "#f30016", "#bc0cae", "#000000"],
		colors: [
			// { name: "平常", color: "#559DD6", text_color: "black", marker_url:require("@/assets/pin_00.png")  }, 
			{ name: "マイナス", color: "#71C0FC", text_color: "black", marker_url:require("@/assets/pin_00.png")  }, 
			{ name: "平常", color: "#71C0FC", text_color: "black", marker_url:require("@/assets/pin_00.png") } 
			,{ name: "注意", color: "#ffc001", text_color: "black", marker_url:require("@/assets/pin_01.png") } 
			,{ name: "警戒", color: "#f30016", text_color: "white", marker_url:require("@/assets/pin_02.png") } 
			,{ name: "危険", color: "#bc0cae", text_color: "white", marker_url:require("@/assets/pin_03.png") } 
			,{ name: "越流", color: "#000000", text_color: "white", marker_url:require("@/assets/pin_04.png") } 
		],
		params_discharge: {
			fulllevel : { color: '#008900', text_color:'white', name:'常時満水位', draw_line: true, draw_legend: true }
			,targetlevel : { color: '#2EAE00', text_color:'white', name:'目標貯水位', draw_line: true, draw_legend: true }
			,discharge_level : { color: 'rgba(57,50 ,178,1)', text_color:'white', name:'事前放流可能水位', draw_line: false, draw_legend: true }
			,predicted_level_bottom : { color: '#00AEAC', text_color:'black', name:'予測回復水位（下限値）', draw_legend: true }
			,predicted_level_middle : { color: '#93F7FF', text_color:'black', name:'予測回復水位（中央値）', draw_legend: true }
			,water_level : { color: 'rgba(113,192,255,0.8)', text_color:'black', name:'観測水位', draw_legend: true }
		},
		params_forecast: {
			fulllevel : { color: '#008900', text_color:'white', name:'常時満水位', draw_line: true, draw_legend: true }
			,predicted_level : { color: '#93F7FF', text_color:'black', name:'予測水位', draw_line: false, draw_legend: true }
			,water_level : { color: 'rgba(113,192,255,0.8)', text_color:'black', name:'観測水位', draw_legend: true }
		}
		,
		yearColors: [
			'#FF0102'
			,'#00A22B'
			,'#21C6FF'
			,'#FFA01A'
			,'#DCDCDC'
			,'#BC0CAF'
			// TODO : add specific colors for more data
		]
		,
		floodColors: {
			rain : { color: '#B7DFFF', name:'雨量' , text_color:'black' }
			, input : { color: '#145FFF', name:'流域流入量(ピーク時)' , text_color:'black' }
			, output : { color: '#FF0DB4', name:'下流放流量(ピーク時)' , text_color:'black' }
			, supression : { color: '#E5E5E5', name:'ため池による最大抑制量' , text_color:'black' }
		}
	},
	getters: {
		getAllPlaces: state => {
			return state.places.summaryData;
		},
		getPlaceIndexById: state => id => {
			return state.places.summaryData.findIndex(element => element.id === id);
		},
		getPlaceById: state => id => {
			// return state.places.summaryData[Object.keys(state.places.summaryData).find(item => item === id)]
			return state.places.summaryData[state.places.summaryData.findIndex(element => element.id === id)];
		},
		getPlaceByIndex: state => index => {
			// return state.places.summaryData[Object.keys(state.places.summaryData).find(item => item === id)]
			return state.places.summaryData[index];
		},
		getPlaceMarkers: state => {
			let data = [];
			// for (const key of Object.keys(this.$store.state.places.summaryData)) {
			for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
				let item = this.$store.state.places.summaryData[i];
				// console.log(item);
				let element = {
					"title": item.name,
					"position": item.position
				};
				data.push(element);
				// console.log(element);
			}
			return data;
		}
		,
		getColors: state => {
			return state.colors;
		}
		,
		getDischargeParams: state => {
			return state.params_discharge;
		},
		getForecastParams: state => {
			return state.params_forecast;
		}
		,
		getAlertColor: state => item => {
			let alertColorIndex = 1;
			for (var i = 0; i < item.alertThresholds.length; i++) {
				if (item.waterlevel.level && item.waterlevel.level[item.waterlevel.level.length-1] >= item.alertThresholds[i]) {
					alertColorIndex = i + 2;
				}
			}
			return state.colors[alertColorIndex];
		}
	},
	actions: {
		login({state}, {data}) {
			// console.log("Login: " + data);
			return axios.post(state.api_url + "/users/login", {
				// email: state.api_email,
				// password: crypto.SHA1(state.api_password).toString(),
				email: data.user,
				password: crypto.SHA1(data.password).toString(),
				groupkey: state.api_groupkey
			})
			.then(res => {
				// 成功した場合
				if (res && res.data && res.status == "200") {
					// console.log("Login Success: " + JSON.stringify(res.data));
					this.commit("auth/SET_DATA", { tokenType: "jwt", token: res.data.content.token });
					return axios.get(state.api_url + "/devices", {
						headers: {
							Authorization: "Bearer " + state.auth.data.token,
						},
					})
					.then(async res => {
						if (res && res.data && res.status == "200") {
							console.log("Device List Success: " + JSON.stringify(res.data));
							// summaryDataがある場合は新たに作らないが、ない場合は作る
							this.dispatch("places/createSummaryContentsData", {data: res.data.content});
							for (let j = 0; j < state.places.summaryData.length; j++) {
								await this.dispatch("setPreDischargeData", { index: j });
								await this.dispatch("setApcpData", { index: j });
								await this.dispatch("setRainData", { index: j });
							}
							return true;
						}
					})
					.catch(error => {
						console.log("Device List Error: " + error);
					})
				}
			}).catch(error => {
				console.log("Login Error: " + error);
			})
		},
		getPlaceParameters({state}, {index}) {
			// console.log(state.api_url + "/points/" + state.places.summaryData[index].pointkey + "/placeParams");
			return axios.get(state.api_url + "/points/vars/" + state.places.summaryData[index].pointkey + "/placeParams", {
				headers: {
					Authorization: "Bearer " + state.auth.data.token
				},
			})
			.then(res => {
				// 成功した場合
				if (res && res.data && res.status == "200") {
					console.log("Get Place Parameters Success: " + JSON.stringify(res.data));
					if (Object.keys(res.data).length) {
						this.dispatch("places/setSummaryContentsData", {index: index, key: "initialized", data: false});
						Object.assign(state.places.summaryData[index] , res.data);
						// アラート名を同期する
						for (let i = 0; i < state.places.summaryData[index].alertThresholdNames.length; i++) {
							this.commit("SET_COLOR", {index: i+2, data: state.places.summaryData[index].alertThresholdNames[state.places.summaryData[index].alertThresholdNames.length- 1 - i]});
						}
					} else {
						if(!state.places.summaryData[index].initialized) {
							alert("まず、管理画面にて溜池諸元の各種パラメータを設定してください");
						}
					}
					return res;
				}
			}).catch(error => {
				console.log("Get Place Parameters Error: " + error);
				alert("一定時間経過したためセッションが無効になりました。再ログインしてください。");
				router.push({ name: "Login", params: {} }).catch(() => {});
			})
		},
		setPlaceParameters({state}, {index, data}) {
			// axios.defaults.headers.common['Content-Type'] = 'application/json';
			// axios.defaults.headers.common["Authorization"] = "Bearer " + state.auth.data.token;
			delete data.name;
			// delete data.position;
			delete data.pointkey;
			delete data.images;
			delete data.waterlevel;
			delete data.rain;
			delete data.apcp;
			delete data.lastUpdate;
			delete data.initialized;
			return axios.post(state.api_url + "/points/vars/" + state.places.summaryData[index].pointkey + "/placeParams", data, {
				headers: {
					Authorization: "Bearer " + state.auth.data.token,
					"Content-Type": "application/json"
				},
			})
			.then(res => {
				// 成功した場合
				if (res && res.data && res.status == "200") {
					console.log("Set Place Parameters Success: " + JSON.stringify(res.data));
					return res;
				}
			}).catch(error => {
				console.log("Set Place Parameters Error: " + error);
				alert("一定時間経過したためセッションが無効になりました。再ログインしてください。");
				router.push({ name: "Login", params: {} }).catch(() => {});
			})
		},
		setRainData({state}, {index}) {
			var pastDate = new Date();
			pastDate.setHours(pastDate.getHours() - 60);
			var fromDateStr = utility.methods.dateFormat(pastDate, "yyyyMMddhhmm");
			var toDateStr = utility.methods.dateFormat(new Date(), "yyyyMMddhhmm");
			console.log(fromDateStr + " - " + toDateStr);
			return axios.get(state.api_url + "/metrics?filter=3:H:average&pointkey=" + state.places.summaryData[index].rain_pointkey + "&from=" + fromDateStr + "&to=" + toDateStr, {
				headers: {
					Authorization: "Bearer " + state.auth.data.token,
				},
			})
			.then(res => {
				console.log("Rain Device Metrics Success: " + JSON.stringify(res.data));
				let data = { ts:[], level:[] }; //["日時", "水位"]
				if (res && res.data && res.status == "200" && res.data.content.length > 0 && res.data.content[0].metrics) {
					for (var i = 0; i < res.data.content[0].metrics.length; i++) {
						var metric = res.data.content[0].metrics[i];
						if (metric.n == "rainfall") {
							var metricDate = new Date(metric.ts.split("+")[0]);
							// metricDate = new Date(metricDate.setHours(metricDate.getHours() - 24));
							data.ts.push(metricDate);
							data.level.push(metric.v / 6.0);
						}
						if ((metric.n == "power_supply") && i >= res.data.content[0].metrics.length-2) {
							this.dispatch("places/setSummaryContentsData", {index: index, key: "rain_sensor_power", data: metric.v});
						}
					}
					// console.log("Water levels: " + JSON.stringify(data));
					this.dispatch("places/setSummaryContentsData", {index: index, key: "rain", data: data});
				}
			})
			.catch(error => {
				console.log("Rain Device Metrics Error: " + error);
			})
		},
		setPreDischargeData({state}, {index}) {
			// console.log("Get predischarged data: " + JSON.stringify(state.places.waterlevelData) + " / index: " + index);
			// this.dispatch("places/unsetWaterlevelData");
			var pastDate = new Date();
			pastDate.setHours(pastDate.getHours() - 60);
			var fromDateStr = utility.methods.dateFormat(pastDate, "yyyyMMddhhmm");
			var toDateStr = utility.methods.dateFormat(new Date(), "yyyyMMddhhmm");
			console.log(fromDateStr + " - " + toDateStr);
			console.log(state.api_url + "/metrics?filter=3:H:average&pointkey=" + state.places.summaryData[index].pointkey + "&from=" + fromDateStr + "&to=" + toDateStr);
			return axios.get(state.api_url + "/metrics?filter=3:H:average&pointkey=" + state.places.summaryData[index].pointkey + "&from=" + fromDateStr + "&to=" + toDateStr, {
				headers: {
					Authorization: "Bearer " + state.auth.data.token,
				},
			})
			.then(res => {
				console.log("Device Metrics Success: " + JSON.stringify(res.data));
				let data = { ts:[], level:[] }; //["日時", "水位"]
				if (res && res.data && res.status == "200" && res.data.content.length > 0 && res.data.content[0].metrics) {
					for (var i = 0; i < res.data.content[0].metrics.length; i++) {
						var metric = res.data.content[0].metrics[i];
						if (metric.n == "water_level") {
							var metricDate = new Date(metric.ts.split("+")[0]);
							// metricDate = new Date(metricDate.setHours(metricDate.getHours() - 24));
							data.ts.push(metricDate);
							data.level.push(metric.v / 100.0);
						}
						if ((metric.n == "power_supply") && i >= res.data.content[0].metrics.length-2) {
							this.dispatch("places/setSummaryContentsData", {index: index, key: "waterlevel_sensor_power", data: metric.v});
						}
					}
					// console.log("Water levels: " + JSON.stringify(data));
					this.dispatch("places/setSummaryContentsData", {index: index, key: "waterlevel", data: data});
				}
			})
			.catch(error => {
				console.log("Device Metrics Error: " + error);
			})
		},
		setApcpData({state}, {index}) {
			return axios.get(state.api_url + "/prism/fe1w/latest/@" + state.places.summaryData[index].position.lat + "," + state.places.summaryData[index].position.lng, {
				headers: {
					Authorization: "Bearer " + state.auth.data.token,
				},
			})
			.then(res => {
				console.log("Apcp Data Success: " + JSON.stringify(res.data));
				let data = {}; //{perturbation: [value,...]}
				if (res && res.data && res.data.content && Object.values(res.data.content).length > 0 && Object.values(res.data.content)[0]) {
					let content = Object.values(res.data.content)[0];
					for (var i = 0; i < content.ensemble.length; i++) {
						let ensData = content.ensemble[i];
						// 11日分のAPCPデータを保持する
						let ens = [];
						let prevDate = new Date(ensData.values[0].ts.split("+")[0]);
						let prevVal = 0;
						let acm = 0;
						for (var j = 0; j < ensData.values.length; j++) {
							if (!ensData.values[j].elements[0].APCP) continue;
							let ts = new Date(ensData.values[j].ts.split("+")[0]);
							// 次の日付に変わった
							if (ts.getDate() != prevDate.getDate()) {
								ens.push(acm);
								// 積算値をリセット
								acm = 0;
							}
							// この時間の値を積算値から求める (直前の積算値を減算する)
							let val = ensData.values[j].elements[0].APCP - prevVal;
							acm += val;
							prevVal = ensData.values[j].elements[0].APCP;
							prevDate = new Date(ts.getTime());
						}
						data[ensData.perturbation] = ens;
					}
					this.dispatch("places/setSummaryContentsData", {index: index, key: "apcp", data: data});
				}
			})
			.catch(error => {
				console.log("Apcp Data Error: " + error);
			})
		},
		async getWaterlevelData({state}, {index, start, end}) {
			var fromDateStr = utility.methods.dateFormat(start, "yyyyMMddhhmm");
			var toDateStr = utility.methods.dateFormat(end, "yyyyMMddhhmm");
			console.log("getWaterlevelData: " + fromDateStr + " - " + toDateStr);
			return axios.get(state.api_url + "/metrics?pointkey=" + state.places.summaryData[index].pointkey + "&from=" + fromDateStr + "&to=" + toDateStr, {
				headers: {
					Authorization: "Bearer " + state.auth.data.token,
				},
			})
			.then(async res => {
				console.log("Device Metrics Success: " + JSON.stringify(res.data));
				let data = { ts:[], level:[] }; //["日時", "水位"]
				if (res && res.data && res.status == "200" && res.data.content.length > 0 && res.data.content[0].metrics) {
					for (var i = 0; i < res.data.content[0].metrics.length; i++) {
						var metric = res.data.content[0].metrics[i];
						if (metric.n == "water_level") {
							var metricDate = new Date(metric.ts.split("+")[0]);
							// metricDate = new Date(metricDate.setHours(metricDate.getHours() - 24));
							data.ts.push(metricDate);
							data.level.push(metric.v / 100.0);
						}
					}
					return data;
				}
			})
			.catch(async error => {
				console.log("Device Metrics Error: " + error);
				alert("一定時間経過したためセッションが無効になりました。再ログインしてください。");
				router.push({ name: "Login", params: {} }).catch(() => {});
			})
		},
		async getWaterlevelYearlyData({state}, {index, start, end}) {
			var fromDateStr = utility.methods.dateFormat(start, "yyyyMMddhhmm");
			var toDateStr = utility.methods.dateFormat(end, "yyyyMMddhhmm");
			console.log("getWaterlevelYearlyData: " + fromDateStr + " - " + toDateStr);
			// console.log(state.api_url + "/metrics?filter=1:D:average&pointkey=" + state.places.summaryData[index].pointkey + "&from=" + fromDateStr + "&to=" + toDateStr);
			let appendedMetrics = [];
			let fetched;
			try {
				fetched = await axios.get(state.api_url + "/metrics?filter=1:D:average&pointkey=" + state.places.summaryData[index].pointkey + "&from=" + fromDateStr + "&to=" + toDateStr, {
					headers: {
						Authorization: "Bearer " + state.auth.data.token,
					}
				});
			} catch (error) {
				alert("一定時間経過したためセッションが無効になりました。再ログインしてください。");
				router.push({ name: "Login", params: {} }).catch(() => {});
			}
			if (fetched && fetched.data && fetched.status == "200" && fetched.data.content.length > 0 && fetched.data.content[0].metrics) {
				appendedMetrics = appendedMetrics.concat(fetched.data.content[0].metrics);
				if (!fetched.data.finished) {
					let flag = true;
					while (flag) {
						let next = await axios.get(state.api_url + "/metrics?next=true", {
							headers: {
								Authorization: "Bearer " + state.auth.data.token,
							}
						});
						if (next && next.data && next.status == "200" && next.data.content.length > 0 && next.data.content[0].metrics) {
							appendedMetrics = appendedMetrics.concat(next.data.content[0].metrics);
							if (next.data.finished) {
								flag = false;
							}
						} else {
							flag = false;
						}
					}
				}
			}
			let count = 0;
			let startYear = fromDateStr.substring(0, 4);
			let data = {labels: [], waterlevels:[[]]};
			for (var i = 0; i < appendedMetrics.length; i++) {
				var metric = appendedMetrics[i];
				if (metric.n == "water_level") {
					let modDate = fromDateStr.substring(0, 4) + metric.ts.substring(4);
					var metricDate = new Date(modDate.split("+")[0]);
					if (startYear != metric.ts.substring(0, 4)) {
						startYear = metric.ts.substring(0, 4);
						data.waterlevels.push([]);
						count++;
					}
					if (count == 0) {
						data.labels.push(metricDate);
					}
					data.waterlevels[count].push({x: metricDate, y: metric.v / 100.0});
				}
			}
			return data;
		},
		async getRainData({state}, {index, start, end}) {
			var fromDateStr = utility.methods.dateFormat(start, "yyyyMMddhhmm");
			var toDateStr = utility.methods.dateFormat(end, "yyyyMMddhhmm");
			console.log("getRainData: " + fromDateStr + " - " + toDateStr);
			return axios.get(state.api_url + "/metrics?pointkey=" + state.places.summaryData[index].rain_pointkey + "&from=" + fromDateStr + "&to=" + toDateStr, {
				headers: {
					Authorization: "Bearer " + state.auth.data.token,
				},
			})
			.then(async res => {
				console.log("Rain Device Metrics Success: " + JSON.stringify(res.data));
				let data = { ts:[], level:[] }; //["日時", "水位"]
				if (res && res.data && res.status == "200" && res.data.content.length > 0 && res.data.content[0].metrics) {
					for (var i = 0; i < res.data.content[0].metrics.length; i++) {
						var metric = res.data.content[0].metrics[i];
						if (metric.n == "rainfall") {
							var metricDate = new Date(metric.ts.split("+")[0]);
							// metricDate = new Date(metricDate.setHours(metricDate.getHours() - 24));
							data.ts.push(metricDate);
							data.level.push(metric.v / 6.0);
						}
					}
					return data;
				}
			})
			.catch(async error => {
				console.log("Device Metrics Error: " + error);
				// alert("一定時間経過したためセッションが無効になりました。再ログインしてください。");
				// router.push({ name: "Login", params: {} }).catch(() => {});
			})
		}
	},
	mutations: {
		SET_COLOR: (state, payload) => {
			state.colors[payload.index].name = payload.data;
		}
	}
})

