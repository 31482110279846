
const state = {
	// summaryData: [
	// 	{
	// 		// Automatically set params
	// 		pointkey: 'pp-000000050',
	// 		rain_pointkey: 'pp-000000062',
	// 		position: {lat: 34.79902451, lng: 134.78822008},
	// 		images: [
	// 			{ img:'/img/img001.jpg', name: '現地画像1', time: '2022.12.28 9:15 更新' }
	// 			,{ img:'/img/img002.jpg', name: '現地画像2', time: '2022.12.28 9:15 更新' }
	// 		],
	// 		// Start of Pond params configurable by admin: 
	// 		id: '00001',
	// 		name: '皿池',
	// 		district: '地区1',
	// 		address: '兵庫県高砂市阿弥陀町２０７',
	// 		fulllevel: 1.53,
	// 		pondBottomArea: 13200.0,
	// 		fulllevelArea: 24000.0,
	// 		topEdgeArea: 29647.06,
	// 		tsutsumiKo: 4.2,
	// 		kozuibakiSuishin: 0.8,
	// 		kozuibakiHaba: 5.1,
	// 		ryuryoKeisu: 2.0,
	// 		souChosuiRyo: 29000,
	// 		slitTsutsumiKo: 4.2,
	// 		slitKirikomiHukasa: 0.5,
	// 		slitSuishin: 1.3,
	// 		slitMansuii: 1.03,
	// 		slitHaba: 0.5,
	// 		slitRyuryoKeisu: 1.3,
	// 		kozuibakiHabaWithoutSlit: 5.1,
	// 		shusuiKoKanjoTanshin: 1.0,
	// 		shusuiKoKanChokkei: 0.2,
	// 		shusuiKoSodoKeisu: 0.013,
	// 		shusuiKoKanroKobai: 0.008,
	// 		shusuiKoRyuryoKeisu: 0.62,
	// 		c: 7058.824,
	// 		vh: [0.0000709172635385497, -8.47016655548666E-10, 1.00765892794019E-14, -6.78585160845456E-20, 1.84894230788467E-25, 0.00909059108572099],
	// 		regressionBottomCoefficient: 165.49,
	// 		regressionBottomOffset: -8570.7,
	// 		regressionCoefficient: 169.71,
	// 		alertThresholdNames: [
	// 			"注意",
	// 			"警戒",
	// 			"危険",
	// 			"越流"
	// 		],
	// 		alertThresholds: [
	// 			0.1,
	// 			0.2,
	// 			0.5,
	// 			0.8
	// 		],
	// 		// Data metrics
	// 		waterlevel: {},
	// 		rain: {},
	// 		apcp: {},
	// 		waterlevel_sensor_power: '3',
	// 		rain_sensor_power: '12',
	// 		lastUpdate : new Date()
	// 	},
	// 	{
	// 		id: '00002',
	// 		name: '惣毛池',
	// 		district: '地区1',
	// 		address: '兵庫県高砂市阿弥陀町２０７',
	// 		pointkey: 'pp-00000004f',
	// 		rain_pointkey: 'pp-000000062',
	// 		rain_sensor_name: '皿池（雨量計）',
	// 		waterlevel_sensorid: 'ss-00000000004f',
	// 		rain_sensorid: 'ss-0000000000a1',
	// 		waterlevel_sensor_power: '3',
	// 		rain_sensor_power: '12',
	// 		position: {lat: 34.805165, lng: 134.78627103},
	// 		// Start of Pond params: 
	// 		fulllevel: 2.7,
	// 		pondBottomArea: 23750.0,
	// 		fulllevelArea: 25000.0,
	// 		topEdgeArea: 26018.52,
	// 		tsutsumiKo: 4.9,
	// 		kozuibakiSuishin: 2.2,
	// 		kozuibakiHaba: 6.9,
	// 		ryuryoKeisu: 2.0,
	// 		souChosuiRyo: 66000,
	// 		slitTsutsumiKo: 4.9,
	// 		slitKirikomiHukasa: 0.5,
	// 		slitSuishin: 2.7,
	// 		slitMansuii: 2.2,
	// 		slitHaba: 0.5,
	// 		slitRyuryoKeisu: 1.3,
	// 		kozuibakiHabaWithoutSlit: 6.4,
	// 		shusuiKoKanjoTanshin: 1.0,
	// 		shusuiKoKanChokkei: 0.125,
	// 		shusuiKoSodoKeisu: 0.013,
	// 		shusuiKoKanroKobai: 0.008,
	// 		shusuiKoRyuryoKeisu: 0.62,
	// 		c: 462.96,
	// 		vh: [0.0000421052476472932, -1.7277960866916E-11, 1.41377433976797E-17, -1.38298344571566E-23, 1.09795745665652E-29, 4.32856272797941E-08],
	// 		regressionBottomCoefficient: 259.71,
	// 		regressionBottomOffset: -19834,
	// 		regressionCoefficient: 261.45,
	// 		alertThresholdNames: [
	// 			"注意",
	// 			"警戒",
	// 			"危険",
	// 			"越流"
	// 		],
	// 		alertThresholds: [
	// 			0.1,
	// 			0.2,
	// 			0.5,
	// 			2.2
	// 		],
	// 		// End of Pond params: 
	// 		images: [
	// 			{ img:'/img/img001.jpg', name: '現地画像1', time: '2022.12.28 9:15 更新' }
	// 			,{ img:'/img/img002.jpg', name: '現地画像2', time: '2022.12.28 9:15 更新' }
	// 		],
	// 		waterlevel: {},
	// 		rain: {},
	// 		apcp: {},
	// 		lastUpdate : new Date()
	// 	}
	// ]
};

const mutations = {
	CREATE_SUMMARY_DATA: (state, payload) => {
		state.summaryData = [];
		for (let i = 0; i < payload.data.length; i++) {
			let point = payload.data[i];
			if (point.sensors && point.sensors.length > 0) {
				for (let j = 0; j < point.sensors.length; j++) {
					let sensor = point.sensors[j];
					if (sensor.name == "water_level") {
						state.summaryData.push({
							pointkey: point.pointkey,
							rain_pointkey: "pp-000000062",
							position: {lat: point.latitude, lng: point.longitude},
							images: [
								{ img:'/img/img001.jpg', name: '現地画像1' }
								,{ img:'/img/img002.jpg', name: '現地画像2' }
							],
							id: ( '00000' + i ).slice( -5 ),
							name: point.name,
							district: '',
							address: '',
							fulllevel: 0,
							pondBottomArea: 0,
							fulllevelArea: 0,
							topEdgeArea: 0,
							tsutsumiKo: 0,
							kozuibakiSuishin: 0,
							kozuibakiHaba: 0,
							ryuryoKeisu: 0,
							souChosuiRyo: 0,
							slitTsutsumiKo: 0,
							slitKirikomiHukasa: 0,
							slitSuishin: 0,
							slitMansuii: 0,
							slitHaba: 0,
							slitRyuryoKeisu: 0,
							kozuibakiHabaWithoutSlit: 0,
							shusuiKoKanjoTanshin: 0,
							shusuiKoKanChokkei: 0,
							shusuiKoSodoKeisu: 0,
							shusuiKoKanroKobai: 0,
							shusuiKoRyuryoKeisu: 0,
							c: 0,
							vh: [0, 0, 0, 0, 0, 0],
							regressionBottomCoefficient: 0,
							regressionBottomOffset: 0,
							regressionCoefficient: 0,
							alertThresholds: [
								0.1,
								0.2,
								0.5,
								0.8
							],
							alertThresholdNames: [
								"注意",
								"警戒",
								"危険",
								"越流"
							],
							waterlevel: {},
							rain: {},
							apcp: {},
							waterlevel_sensor_power: '',
							rain_sensor_power: '',
							lastUpdate : new Date(),
							initialized: true
						});
					}
				}
			}
		}
	},
	SET_SUMMARY_CONTENTS_DATA: (state, payload) => {
		state.summaryData[payload.index][payload.key] = payload.data;
	}
};
const actions = {
	createSummaryContentsData({ commit }, payload) {
		if (!state.summaryData) {
			commit("CREATE_SUMMARY_DATA", payload);
		}
	},
	setSummaryContentsData({ commit }, payload) {
		commit("SET_SUMMARY_CONTENTS_DATA", payload);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
