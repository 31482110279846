
const state = {
	data: {
		token: false,
		tokenType: "",
		action: ""
	}
};

const mutations = {
	UNSET_DATA: (state) => {
		state.data.token = false;
		state.data.tokenType = "";
		state.data.action = "logout";
	},
	SET_DATA: (state, payload) => {
		state.data.token = payload.token;
		state.data.tokenType = payload.tokenType;
		state.data.action = "logeedin";
	},
	RESET_ACTION: (state) => {
		state.data.action = "";
	}
};
const actions = {
	unsetData({ commit }) {
		commit("UNSET_DATA");
	},
	setData({ commit }, payload) {
		commit("SET_DATA", payload);
	},
	resetAction({ commit }) {
		commit("RESET_ACTION");
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
