
const state = {
	data: {
		id: "",
		name: "",
		email: "",
		image: "",
		statusMessage: "",
		role: ""
	}
};

const mutations = {
	UNSET_DATA: (state) => {
		state.data.id = "";
		state.data.name = "";
		state.data.email = "";
		state.data.image = "";
		state.data.statusMessage = "";
		state.data.role = "";
	},
	SET_DATA: (state, payload) => {
		state.data.id = payload.id;
		state.data.name = payload.name;
		state.data.email = payload.email;
		state.data.image = payload.image;
		state.data.statusMessage = payload.statusMessage;
		state.data.role = payload.role;
	}
};
const actions = {
	unsetData({ commit }) {
		commit("UNSET_DATA");
	},
	setData({ commit }, payload) {
		commit("SET_DATA", payload);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
